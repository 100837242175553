import useErrorConfig from '@/utils/errorConfig'

export const useSallyFetchCms: typeof useFetch = (request, opts?: any) => {
  const config = useRuntimeConfig()
  const token = useCookie('token')
  const loaderStore = useLoader()

  return useFetch(request, {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
    baseURL: config.public.baseApiUrl,
    onRequest() {
      loaderStore.startLoading()
    },
    onResponse: () => {
      loaderStore.stopLoading()
    },
    onResponseError: (error) => {
      loaderStore.stopLoading()
      const errorCode = error.response.status
      if (errorCode === 401) {
        const tokenCookie = useCookie('token')
        const userCookie = useCookie('user')
        tokenCookie.value = null
        userCookie.value = null
        return navigateTo('/login')
      }

      if (useErrorConfig().isRedirect(String(errorCode)))
        return navigateTo(`/error/${errorCode}`)
    },
    ...opts,
  })
}
